import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ea504e86 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _c0e795a8 = () => interopDefault(import('..\\pages\\career\\index.vue' /* webpackChunkName: "pages/career/index" */))
const _3b5e5738 = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages/contact" */))
const _8e5a7d2c = () => interopDefault(import('..\\pages\\gallery.vue' /* webpackChunkName: "pages/gallery" */))
const _8ac86706 = () => interopDefault(import('..\\pages\\global-presence.vue' /* webpackChunkName: "pages/global-presence" */))
const _df0b7340 = () => interopDefault(import('..\\pages\\local-information\\index.vue' /* webpackChunkName: "pages/local-information/index" */))
const _7a30db6b = () => interopDefault(import('..\\pages\\news.vue' /* webpackChunkName: "pages/news" */))
const _21a4dca2 = () => interopDefault(import('..\\pages\\privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _649de832 = () => interopDefault(import('..\\pages\\solutions.vue' /* webpackChunkName: "pages/solutions" */))
const _c9a1fa3a = () => interopDefault(import('..\\pages\\tempvideo\\index.vue' /* webpackChunkName: "pages/tempvideo/index" */))
const _76971d4f = () => interopDefault(import('..\\pages\\tracking.vue' /* webpackChunkName: "pages/tracking" */))
const _689306be = () => interopDefault(import('..\\pages\\about\\certificates.vue' /* webpackChunkName: "pages/about/certificates" */))
const _38209abf = () => interopDefault(import('..\\pages\\about\\history.vue' /* webpackChunkName: "pages/about/history" */))
const _502b7975 = () => interopDefault(import('..\\pages\\about\\numbers.vue' /* webpackChunkName: "pages/about/numbers" */))
const _34d596f2 = () => interopDefault(import('..\\pages\\about\\terms.vue' /* webpackChunkName: "pages/about/terms" */))
const _5c44efd0 = () => interopDefault(import('..\\pages\\career\\application\\index.vue' /* webpackChunkName: "pages/career/application/index" */))
const _55486238 = () => interopDefault(import('..\\pages\\career\\programletnjeprakse\\index.vue' /* webpackChunkName: "pages/career/programletnjeprakse/index" */))
const _673edd14 = () => interopDefault(import('..\\pages\\solution\\automotive-logistics\\index.vue' /* webpackChunkName: "pages/solution/automotive-logistics/index" */))
const _7c0ac139 = () => interopDefault(import('..\\pages\\solution\\contract-logistics\\index.vue' /* webpackChunkName: "pages/solution/contract-logistics/index" */))
const _1d2c22d2 = () => interopDefault(import('..\\pages\\career\\programletnjeprakse\\galerija\\index.vue' /* webpackChunkName: "pages/career/programletnjeprakse/galerija/index" */))
const _3dbed401 = () => interopDefault(import('..\\pages\\career\\programletnjeprakse\\iskustvakolega\\index.vue' /* webpackChunkName: "pages/career/programletnjeprakse/iskustvakolega/index" */))
const _6c3d5dfd = () => interopDefault(import('..\\pages\\career\\programletnjeprakse\\praksainformacije\\index.vue' /* webpackChunkName: "pages/career/programletnjeprakse/praksainformacije/index" */))
const _cf8bdbb2 = () => interopDefault(import('..\\pages\\career\\programletnjeprakse\\stipendiranjestudenata\\index.vue' /* webpackChunkName: "pages/career/programletnjeprakse/stipendiranjestudenata/index" */))
const _2d48af6c = () => interopDefault(import('..\\pages\\career\\programletnjeprakse\\usloviucesca\\index.vue' /* webpackChunkName: "pages/career/programletnjeprakse/usloviucesca/index" */))
const _50bad941 = () => interopDefault(import('..\\pages\\career\\programletnjeprakse\\viseoprogramu\\index.vue' /* webpackChunkName: "pages/career/programletnjeprakse/viseoprogramu/index" */))
const _53c45328 = () => interopDefault(import('..\\pages\\local-information\\albania\\m-one-i.vue' /* webpackChunkName: "pages/local-information/albania/m-one-i" */))
const _5b6d0ed8 = () => interopDefault(import('..\\pages\\local-information\\bosnia\\m-one.vue' /* webpackChunkName: "pages/local-information/bosnia/m-one" */))
const _6341a170 = () => interopDefault(import('..\\pages\\local-information\\bosnia\\m-one-i.vue' /* webpackChunkName: "pages/local-information/bosnia/m-one-i" */))
const _30ac3350 = () => interopDefault(import('..\\pages\\local-information\\bosnia\\warehouse-lukavica-sarajevo\\index.vue' /* webpackChunkName: "pages/local-information/bosnia/warehouse-lukavica-sarajevo/index" */))
const _d3aa7458 = () => interopDefault(import('..\\pages\\local-information\\croatia\\warehouse-bobovica\\index.vue' /* webpackChunkName: "pages/local-information/croatia/warehouse-bobovica/index" */))
const _9d5b6602 = () => interopDefault(import('..\\pages\\local-information\\macedonia\\m-one-i.vue' /* webpackChunkName: "pages/local-information/macedonia/m-one-i" */))
const _42131380 = () => interopDefault(import('..\\pages\\local-information\\montenegro\\m-one-i.vue' /* webpackChunkName: "pages/local-information/montenegro/m-one-i" */))
const _6ade22ca = () => interopDefault(import('..\\pages\\local-information\\slovenia\\logatec-warehouse\\index.vue' /* webpackChunkName: "pages/local-information/slovenia/logatec-warehouse/index" */))
const _0e72090b = () => interopDefault(import('..\\pages\\local-information\\usa\\air-sea.vue' /* webpackChunkName: "pages/local-information/usa/air-sea" */))
const _2baa38fc = () => interopDefault(import('..\\pages\\local-information\\usa\\freight.vue' /* webpackChunkName: "pages/local-information/usa/freight" */))
const _e7125a2c = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _6aac1fa4 = () => interopDefault(import('..\\pages\\solution\\automotive-logistics\\_slug.vue' /* webpackChunkName: "pages/solution/automotive-logistics/_slug" */))
const _7a541ff1 = () => interopDefault(import('..\\pages\\solution\\contract-logistics\\_slug.vue' /* webpackChunkName: "pages/solution/contract-logistics/_slug" */))
const _e278b5d0 = () => interopDefault(import('..\\pages\\local-information\\_slug.vue' /* webpackChunkName: "pages/local-information/_slug" */))
const _de1b0cfc = () => interopDefault(import('..\\pages\\post\\_slug.vue' /* webpackChunkName: "pages/post/_slug" */))
const _57fa3309 = () => interopDefault(import('..\\pages\\solution\\_slug.vue' /* webpackChunkName: "pages/solution/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about/",
    component: _ea504e86,
    name: "about___en"
  }, {
    path: "/sr/about/",
    component: _ea504e86,
    name: "about___sr"
  }, {
    path: "/career/",
    component: _c0e795a8,
    name: "career___en"
  }, {
    path: "/sr/career/",
    component: _c0e795a8,
    name: "career___sr"
  }, {
    path: "/contact/",
    component: _3b5e5738,
    name: "contact___en"
  }, {
    path: "/sr/contact/",
    component: _3b5e5738,
    name: "contact___sr"
  }, {
    path: "/gallery/",
    component: _8e5a7d2c,
    name: "gallery___en"
  }, {
    path: "/sr/gallery/",
    component: _8e5a7d2c,
    name: "gallery___sr"
  }, {
    path: "/global-presence/",
    component: _8ac86706,
    name: "global-presence___en"
  }, {
    path: "/sr/global-presence/",
    component: _8ac86706,
    name: "global-presence___sr"
  }, {
    path: "/local-information/",
    component: _df0b7340,
    name: "local-information___en"
  }, {
    path: "/sr/local-information/",
    component: _df0b7340,
    name: "local-information___sr"
  }, {
    path: "/news/",
    component: _7a30db6b,
    name: "news___en"
  }, {
    path: "/sr/news/",
    component: _7a30db6b,
    name: "news___sr"
  }, {
    path: "/privacy-policy/",
    component: _21a4dca2,
    name: "privacy-policy___en"
  }, {
    path: "/sr/privacy-policy/",
    component: _21a4dca2,
    name: "privacy-policy___sr"
  }, {
    path: "/solutions/",
    component: _649de832,
    name: "solutions___en"
  }, {
    path: "/sr/solutions/",
    component: _649de832,
    name: "solutions___sr"
  }, {
    path: "/tempvideo/",
    component: _c9a1fa3a,
    name: "tempvideo___en"
  }, {
    path: "/sr/tempvideo/",
    component: _c9a1fa3a,
    name: "tempvideo___sr"
  }, {
    path: "/tracking/",
    component: _76971d4f,
    name: "tracking___en"
  }, {
    path: "/sr/tracking/",
    component: _76971d4f,
    name: "tracking___sr"
  }, {
    path: "/about/certificates/",
    component: _689306be,
    name: "about-certificates___en"
  }, {
    path: "/sr/about/certificates/",
    component: _689306be,
    name: "about-certificates___sr"
  }, {
    path: "/about/history/",
    component: _38209abf,
    name: "about-history___en"
  }, {
    path: "/sr/about/history/",
    component: _38209abf,
    name: "about-history___sr"
  }, {
    path: "/about/numbers/",
    component: _502b7975,
    name: "about-numbers___en"
  }, {
    path: "/sr/about/numbers/",
    component: _502b7975,
    name: "about-numbers___sr"
  }, {
    path: "/about/terms/",
    component: _34d596f2,
    name: "about-terms___en"
  }, {
    path: "/sr/about/terms/",
    component: _34d596f2,
    name: "about-terms___sr"
  }, {
    path: "/career/application/",
    component: _5c44efd0,
    name: "career-application___en"
  }, {
    path: "/sr/career/application/",
    component: _5c44efd0,
    name: "career-application___sr"
  }, {
    path: "/career/programletnjeprakse/",
    component: _55486238,
    name: "career-programletnjeprakse___en"
  }, {
    path: "/sr/career/programletnjeprakse/",
    component: _55486238,
    name: "career-programletnjeprakse___sr"
  }, {
    path: "/solution/automotive-logistics/",
    component: _673edd14,
    name: "solution-automotive-logistics___en"
  }, {
    path: "/sr/solution/automotive-logistics/",
    component: _673edd14,
    name: "solution-automotive-logistics___sr"
  }, {
    path: "/solution/contract-logistics/",
    component: _7c0ac139,
    name: "solution-contract-logistics___en"
  }, {
    path: "/sr/solution/contract-logistics/",
    component: _7c0ac139,
    name: "solution-contract-logistics___sr"
  }, {
    path: "/career/programletnjeprakse/galerija/",
    component: _1d2c22d2,
    name: "career-programletnjeprakse-galerija___en"
  }, {
    path: "/sr/career/programletnjeprakse/galerija/",
    component: _1d2c22d2,
    name: "career-programletnjeprakse-galerija___sr"
  }, {
    path: "/career/programletnjeprakse/iskustvakolega/",
    component: _3dbed401,
    name: "career-programletnjeprakse-iskustvakolega___en"
  }, {
    path: "/sr/career/programletnjeprakse/iskustvakolega/",
    component: _3dbed401,
    name: "career-programletnjeprakse-iskustvakolega___sr"
  }, {
    path: "/career/programletnjeprakse/praksainformacije/",
    component: _6c3d5dfd,
    name: "career-programletnjeprakse-praksainformacije___en"
  }, {
    path: "/sr/career/programletnjeprakse/praksainformacije/",
    component: _6c3d5dfd,
    name: "career-programletnjeprakse-praksainformacije___sr"
  }, {
    path: "/career/programletnjeprakse/stipendiranjestudenata/",
    component: _cf8bdbb2,
    name: "career-programletnjeprakse-stipendiranjestudenata___en"
  }, {
    path: "/sr/career/programletnjeprakse/stipendiranjestudenata/",
    component: _cf8bdbb2,
    name: "career-programletnjeprakse-stipendiranjestudenata___sr"
  }, {
    path: "/career/programletnjeprakse/usloviucesca/",
    component: _2d48af6c,
    name: "career-programletnjeprakse-usloviucesca___en"
  }, {
    path: "/sr/career/programletnjeprakse/usloviucesca/",
    component: _2d48af6c,
    name: "career-programletnjeprakse-usloviucesca___sr"
  }, {
    path: "/career/programletnjeprakse/viseoprogramu/",
    component: _50bad941,
    name: "career-programletnjeprakse-viseoprogramu___en"
  }, {
    path: "/sr/career/programletnjeprakse/viseoprogramu/",
    component: _50bad941,
    name: "career-programletnjeprakse-viseoprogramu___sr"
  }, {
    path: "/local-information/albania/m-one-i/",
    component: _53c45328,
    name: "local-information-albania-m-one-i___en"
  }, {
    path: "/sr/local-information/albania/m-one-i/",
    component: _53c45328,
    name: "local-information-albania-m-one-i___sr"
  }, {
    path: "/local-information/bosnia/m-one/",
    component: _5b6d0ed8,
    name: "local-information-bosnia-m-one___en"
  }, {
    path: "/sr/local-information/bosnia/m-one/",
    component: _5b6d0ed8,
    name: "local-information-bosnia-m-one___sr"
  }, {
    path: "/local-information/bosnia/m-one-i/",
    component: _6341a170,
    name: "local-information-bosnia-m-one-i___en"
  }, {
    path: "/sr/local-information/bosnia/m-one-i/",
    component: _6341a170,
    name: "local-information-bosnia-m-one-i___sr"
  }, {
    path: "/local-information/bosnia/warehouse-lukavica-sarajevo/",
    component: _30ac3350,
    name: "local-information-bosnia-warehouse-lukavica-sarajevo___en"
  }, {
    path: "/sr/local-information/bosnia/warehouse-lukavica-sarajevo/",
    component: _30ac3350,
    name: "local-information-bosnia-warehouse-lukavica-sarajevo___sr"
  }, {
    path: "/local-information/croatia/warehouse-bobovica/",
    component: _d3aa7458,
    name: "local-information-croatia-warehouse-bobovica___en"
  }, {
    path: "/sr/local-information/croatia/warehouse-bobovica/",
    component: _d3aa7458,
    name: "local-information-croatia-warehouse-bobovica___sr"
  }, {
    path: "/local-information/macedonia/m-one-i/",
    component: _9d5b6602,
    name: "local-information-macedonia-m-one-i___en"
  }, {
    path: "/sr/local-information/macedonia/m-one-i/",
    component: _9d5b6602,
    name: "local-information-macedonia-m-one-i___sr"
  }, {
    path: "/local-information/montenegro/m-one-i/",
    component: _42131380,
    name: "local-information-montenegro-m-one-i___en"
  }, {
    path: "/sr/local-information/montenegro/m-one-i/",
    component: _42131380,
    name: "local-information-montenegro-m-one-i___sr"
  }, {
    path: "/local-information/slovenia/logatec-warehouse/",
    component: _6ade22ca,
    name: "local-information-slovenia-logatec-warehouse___en"
  }, {
    path: "/sr/local-information/slovenia/logatec-warehouse/",
    component: _6ade22ca,
    name: "local-information-slovenia-logatec-warehouse___sr"
  }, {
    path: "/local-information/usa/air-sea/",
    component: _0e72090b,
    name: "local-information-usa-air-sea___en"
  }, {
    path: "/sr/local-information/usa/air-sea/",
    component: _0e72090b,
    name: "local-information-usa-air-sea___sr"
  }, {
    path: "/local-information/usa/freight/",
    component: _2baa38fc,
    name: "local-information-usa-freight___en"
  }, {
    path: "/sr/local-information/usa/freight/",
    component: _2baa38fc,
    name: "local-information-usa-freight___sr"
  }, {
    path: "/",
    component: _e7125a2c,
    name: "index___en"
  }, {
    path: "/sr/",
    component: _e7125a2c,
    name: "index___sr"
  }, {
    path: "/solution/automotive-logistics/:slug?/",
    component: _6aac1fa4,
    name: "solution-automotive-logistics-slug___en"
  }, {
    path: "/sr/solution/automotive-logistics/:slug?/",
    component: _6aac1fa4,
    name: "solution-automotive-logistics-slug___sr"
  }, {
    path: "/solution/contract-logistics/:slug?/",
    component: _7a541ff1,
    name: "solution-contract-logistics-slug___en"
  }, {
    path: "/sr/solution/contract-logistics/:slug?/",
    component: _7a541ff1,
    name: "solution-contract-logistics-slug___sr"
  }, {
    path: "/local-information/:slug?/",
    component: _e278b5d0,
    name: "local-information-slug___en"
  }, {
    path: "/sr/local-information/:slug?/",
    component: _e278b5d0,
    name: "local-information-slug___sr"
  }, {
    path: "/post/:slug?/",
    component: _de1b0cfc,
    name: "post-slug___en"
  }, {
    path: "/sr/post/:slug?/",
    component: _de1b0cfc,
    name: "post-slug___sr"
  }, {
    path: "/solution/:slug?/",
    component: _57fa3309,
    name: "solution-slug___en"
  }, {
    path: "/sr/solution/:slug?/",
    component: _57fa3309,
    name: "solution-slug___sr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
